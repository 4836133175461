
import { defineComponent } from "vue";
//import { Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRoute } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "trainee-details",
  components: {
    //Datatable,
  },
  setup() {
    const route = useRoute();
    const traineeInfoID = route.params.id;
    return { traineeInfoID };
  },
  data() {
    return {
      batch: {},
      traineeInfos: [],
      weekplan: {},
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getTraineeInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getTraineeInfos() {
    await ApiService.get("trainee/view/" + this.traineeInfoID)
        .then((response) => {
          this.traineeInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
});
